import React from 'react';
import ReactDOM from 'react-dom/client';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';

import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

import './assets/scss/index.scss';
import App from './App';

import global_es from "./translations/Es/global.json";
import global_en from "./translations/En/global.json";

i18next.init({
  interpolation: { escapeValue: false },
  lng: "en",
  resources: {
    es: {
      global: global_es
    },
    en: {
      global: global_en
    }
  },
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>

      <App />
    </I18nextProvider>
  </React.StrictMode>
);
