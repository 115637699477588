import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes, redirect } from "react-router-dom";
// componentes
import Loader from './components/loader';
const Redes = lazy(() => import('./components/redes'));
const Header = lazy(() => import('./components/header'));
const Footer = lazy(() => import('./components/footer'));
// pages
const Home = lazy(() => import('./pages/home'));
const About_Us = lazy(() => import('./pages/about_us'));
const Bokkie_Software = lazy(() => import('./pages/bookie_software'));
const Software_Features = lazy(() => import('./pages/software_features'));
const Promotions = lazy(() => import('./pages/promotions'));
const Products = lazy(() => import('./pages/products'));
const Contact = lazy(() => import('./pages/contact'));
const Casino = lazy(() => import('./pages/casino'));
const Form_New_Agent = lazy(() => import('./pages/form_new_aget'));
const Sign_Up = lazy(() => import('./pages/sign_up'));
const Error_404 = lazy(() => import('./pages/404'));
function App() {
  return (
    <div className="App overflow-hidden">
      <BrowserRouter>
        <Suspense fallback={<Loader />}>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="inicio" element={<Home />} />
            <Route path="sobre-nosotros" element={<About_Us />} />
            <Route path="software" element={<Bokkie_Software />} />
            <Route path="software-caracteristicas" element={<Software_Features />} />
            <Route path="promociones" element={<Promotions />} />
            <Route path="productos" element={<Products />} />
            <Route path="contacto" element={<Contact />} />
            <Route path="casino" element={<Casino />} />
            <Route path="form_new_agent" element={<Form_New_Agent />} />
            <Route path="sign-up" element={<Sign_Up />} />
            <Route path="404" element={<Error_404 />} />
            <Route path="*" element={<Error_404 />} />
          </Routes>
          <Footer />
          <Redes />
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
